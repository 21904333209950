import { render, staticRenderFns } from "./offer_pj.vue?vue&type=template&id=75251475&scoped=true&"
import script from "./offer_pj.vue?vue&type=script&lang=js&"
export * from "./offer_pj.vue?vue&type=script&lang=js&"
import style0 from "./offer_pj.vue?vue&type=style&index=0&id=75251475&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75251475",
  null
  
)

export default component.exports